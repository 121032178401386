.active{
  background-color: red;
}
#sidebar{
  background-color: #2c3e50;
  color: #fff;
  transition: all 0.3s;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  width: 250px;
  z-index: 999;
}
#sidebar > *{
  padding:10px;
}
#App{
  margin-left: 250px;
  transition: all 0.3s;
}
/* default styling. Nothing to do with freexing first row and column */
main {display: flex;}
main > * {border: 1px solid;}
table {border-collapse: collapse; font-family: helvetica}
td, th {border:  1px solid;
      padding: 10px;
      min-width: 10px;
      background: white;
      box-sizing: border-box;
      text-align: left;
}
.table-container {
  position: relative;
  max-height:  300px;
  width: 500px;
  overflow: scroll;
}

thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  background: hsl(20, 50%, 70%);
}

thead th:first-child {
  left: 0;
  z-index: 3;
}

tfoot {
  position: -webkit-sticky;
  bottom: 0;
  z-index: 2;
}

tfoot td {
  position: sticky;
  bottom: 0;
  z-index: 2;
  background: hsl(20, 50%, 70%);
}

tfoot td:first-child {
  z-index: 3;
}

tbody {
  overflow: scroll;
  height: 200px;
}

/* MAKE LEFT COLUMN FIXEZ */
tr > :first-child {
  position: -webkit-sticky;
  position: sticky; 
  background: hsl(180, 50%, 70%);
  left: 0; 
}
/* don't do this */
tr > :first-child {
  box-shadow: inset 0px 1px black;
}

/* colour for marks */
.present{
  background-color: #00ff59;
}

.approved{
  background-color: #71e208;
}

.unjudged{
  background-color: #e2e208;
}

.invalidexcuse{
  background-color: #e27108;
}

.absent{
  background-color: #ff0000;
}

.nodata{
  background-color: #311818;
}

td p{
  margin: 0;
  padding:10px;
  border:10px;
}
td{
  padding: 0;
}

.container{
  border-top: navy 2px solid;
  border-bottom: navy 2px solid;
}

.error{
  border: red 2px solid;
  background-color: lightcoral;
  color:darkred;
}

.dev{
  background-color: green;
}